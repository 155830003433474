<template>
  <div class="login-out">
    <div class="no-success" v-if="!showSuccess">
      <div class="title">解除绑定手机号</div>
      <div class="mobile-img">
        <!-- <img src="@/assets/mobile.png" /> -->
        <img src="@/assets/images/login/mobile.svg" />
      </div>
      <div class="input-wrapper">已绑定手机号{{ currentUserName }}</div>
      <div class="quit" @click="loginOut"><span>解除绑定</span></div>
    </div>
    <div class="success" v-if="showSuccess">
      <div class="mobile-img">
        <img src="@/assets/images/login/mobile-success.svg" />
      </div>
      <div class="title">解除绑定手机号</div>
    </div>
  </div>
</template>
<script>
import { cancelAuth } from '@/api/open'

export default {
  data() {
    return {
      showSuccess: false,
    }
  },
  computed: {
    currentUserName() {
      if (this.$store.state.userInfo) {
        return this.$store.state.userInfo.userMobile
      } else {
        return ''
      }
    },
  },
  methods: {
    loginOut() {
      cancelAuth({ openId: localStorage.openId }).then(res => {
        if (res.code === 0) {
          this.$notify({ type: 'success', message: '退出成功' })
          this.$store.commit('setUserInfo', null)
          //this['$router'].push('/login')
          this.showSuccess = true
        }
      })
    },
  },
}
</script>
<style lang="scss">
.login-out {
  width: 100%;
  padding-top: 8vh;
  .no-success {
    .title {
      padding-left: 16px;
      font-size: 22px;
      font-weight: 600;
      text-align: left;
      color: #333333;
    }

    .mobile-img {
      width: 80px;
      margin: 30px auto;
      img {
        width: 100%;
      }
    }

    .input-wrapper {
      margin: 8px auto 0 auto;
      color: #999999;
      font-size: 18px;
      text-align: center;
    }
    .quit {
      background-color: #1891ff;
      color: #ffffff;
      border-radius: 4px;
      width: 90%;
      padding: 18px 0;
      font-size: 0.4rem;
      margin: 1rem auto 0 auto;
      text-align: center;
    }
  }

  .success {
    .mobile-img {
      width: 80px;
      margin: 30px auto;
      img {
        width: 100%;
      }
    }
    .title {
      padding-left: 16px;
      font-size: 22px;
      font-weight: 600;
      text-align: center;
      color: #333333;
    }
  }
}
</style>
